import React, { useState } from "react"
import { Page, Layout, LegacyCard, LegacyStack, Button, Text, CalloutCard, Icon, Collapsible, Box } from "@shopify/polaris";
import { ChevronDownIcon, ChevronUpIcon } from "@shopify/polaris-icons";
import styled from 'styled-components'
import CardDivider from "../../../components/cardDivider";
const ButtonWrapper = styled.button`
  width: 100%;
  cursor: pointer;
  border: none;
  background: none;
  text-align: left;
`
const questions = [
    {
    title: 'General',
    items: [
        {
            title: "Getting started guide",
            desp: "Let's get started with UpsellPlus!",
            link: "https://help.upsellplus.com/en/article/getting-started-guide-hzw3xf/"
        },
        {
            title: "How can I show an upsell based on certain conditions being met? (Smart Rules)",
            desp: "UpsellPlus uses Smart Rules to allow you to set rules or conditions for when an upsell should show. You can find the Smart Rules at the bottom of the configurator for each type of upsell.",
            link: "https://help.upsellplus.com/en/article/how-can-i-show-an-upsell-based-on-certain-conditions-being-met-smart-rules-l1w7y3/"
        },
        {
            title: "How to Remove the upsell_ID from customer facing info?",
            desp: "When you add upsells using the UpsellPlus app, we use an upsell_ID to track your upsell results, like revenue, impressions and conversions.",
            link: "https://help.upsellplus.com/en/article/how-to-remove-the-upsellid-from-customer-facing-info-uuo5ho/"
        },
        {
            title: "How to swap a cart product for an upsell product once added?",
            desp: "A true upsell is upgrading the customer's purchase from a smaller size to a larger size of the same item.",
            link: "https://help.upsellplus.com/en/article/how-to-swap-a-cart-product-for-an-upsell-product-once-added-ndrzvx/"
        },
    ]
    },
    {
    title: 'Checkout',
    items: [
        {
            title: "How to get started: checkout upsells > checkout extensions",
            desp: "‍‍Configure how you want the checkout upsell to work",
            link: "https://help.upsellplus.com/en/article/how-to-get-started-checkout-upsells-checkout-extensions-kr97nq/"
        },
        {
            title: "How to upsell to a subscription in checkout and cart?",
            desp: "To upsell or cross sell to a subscription you can use UpsellPlus to upsell one off purchases to subscriptions in the checkout and cart. The steps below show the process for offering a subscription in the checkout.",
            link: "https://help.upsellplus.com/en/article/how-to-upsell-to-a-subscription-in-checkout-and-cart-htbhu8/"
        },
        {
            title: "How to A/B test checkout upsells",
            desp: "‍‍To find which upsell works best in your checkout, you can now use A/B testing within UpsellPlus.‍",
            link: "https://help.upsellplus.com/en/article/how-to-ab-test-checkout-upsells-16yffqi/"
        },
        
        {
            title: "How to enable checkout upsells using the Checkout Editor",
            desp: "‍‍To launch an upsell you created with UpsellPlus in your checkout using Checkout Extensions, you’ll use the Checkout Editor in your Shopify admin settings.",
            link: "https://help.upsellplus.com/en/article/how-to-enable-checkout-upsells-using-the-checkout-editor-1ga86th/"
        },
    ]
    },
    {
        title: 'Featured & Frequently asked questions',
        items: [
            {
                title: "Intro to discounts",
                desp: "To add a discount to upsells, you can just add in the amount or percentage you want to discount. When the item gets added to cart, the discount will be applied to the upsell.",
                link: "https://help.upsellplus.com/en/article/intro-to-discounts-yguu96/"
            },
            {
                title: "How to upsell to a subscription in checkout and cart?",
                desp: "To upsell or cross sell to a subscription you can use UpsellPlus to upsell one off purchases to subscriptions in the checkout and cart. The steps below show the process for offering a subscription in the checkout.",
                link: "https://help.upsellplus.com/en/article/how-to-upsell-to-a-subscription-in-checkout-and-cart-htbhu8/"
            },
            {
                title: "How to add a free shipping bar to your checkout?",
                desp: "With our Rewards Bar, you can nudge your customers to spend a little more to get free shipping or a free gift with purchase.",
                link: "https://help.upsellplus.com/en/article/how-to-add-a-free-shipping-bar-to-your-checkout-ibr8aj/"
            },
            {
                title: "How to get started: cart upsells > cart drawer?",
                desp: "Note: this is a technical document that will require some understanding of Shopify theme code. If you don't understand it or are unsure, please reach out to your developer or us to set this up.",
                link: "https://help.upsellplus.com/en/article/how-to-get-started-cart-upsells-cart-drawer-apfde9/"
            },
        ]
        }
]
function Helpdesk(props) {
    const [open, setOpen] = useState("0-0")
  const handleToggle = (index) => {
    if (open === index) {
      setOpen(-1)
    } else {
      setOpen(index)
    }
  }
  console.log("open", open)
        const dropdownButtonMarkup = (title, open) => {
            return (
            <LegacyStack alignment="center">
                <LegacyStack.Item fill>
                <Text as="p" fontWeight="bold">{title}</Text>
                </LegacyStack.Item>
                <LegacyStack.Item>
                {open ? (
                    <Icon source={ChevronUpIcon}></Icon>
                ) : (
                    <Icon source={ChevronDownIcon}></Icon>
                )}
                </LegacyStack.Item>
            </LegacyStack>
            );
        }
        return (
            <Page title="Helpdesk">
                <Layout>
                    <Layout.Section>
                    <CalloutCard
                        title="Need Help ?"
                        illustration="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/help-desk.svg?alt=media&token=231d1325-758a-478b-977e-3dde66f4c1de"
                        primaryAction={
                        {
                            url: `https://help.upsellplus.com/`,
                            content: 'Visit Helpdesk',
                            external: true,
                            target: '_blank'
                        }}
                        >
                        <p>Browse through some of the most popular topics here or visit our helpdesk for 50+ resources and FAQ’s.</p>
                        <p>Learn about Discounts, Checkout Extensions, SetupCart Drawer and more.</p>
                    </CalloutCard>
                        
                    </Layout.Section>
                    <Layout.Section>
                        {
                            questions.map((question, qIndex) => {
                                return (
                                    <LegacyCard title={question.title}>
                                        <CardDivider/>
                                        {question.items.map((item, iIndex) => {
                                       return <div>
                                        <LegacyCard.Section>
                                        <ButtonWrapper
                                        onClick={(e) => {
                                            e.preventDefault()
                                            handleToggle(qIndex+"-"+iIndex)
                                        }}
                                        ariaExpanded={open === qIndex+"-"+iIndex}
                                        ariaControls={`primary-button-collapsible`}
                                        >
                                        {dropdownButtonMarkup(
                                            item.title,
                                            open === qIndex+"-"+iIndex
                                        )}
                                        </ButtonWrapper>
                                        </LegacyCard.Section>
                                        {open !== qIndex+"-"+iIndex &&
                                        <CardDivider/>
                                        }
                                        {open === qIndex+"-"+iIndex && (
                                        <LegacyCard.Section>
                                        <Collapsible
                                            open={open === qIndex+"-"+iIndex}
                                            id={`primary-button-collapsible`}
                                            transition={{
                                            duration: "500ms",
                                            timingFunction: "ease-in-out",
                                            }}
                                            expandOnPrint
                                        >
                                                <Box padding="200">
                                                    <Text as="p">{item.desp}</Text>
                                                </Box>
                                                <Box paddingBlockEnd="200">
                                                <Button onClick={() => {
                                                     window.open(
                                                        item.link,
                                                        "_blank"
                                                      )
                                                }}>Learn more</Button>
                                                </Box>
                                        </Collapsible>
                                        </LegacyCard.Section>
                                        )}
                                        </div> 
                                    })}
                        
                                    </LegacyCard>
                                )
                            })
                        }
                    </Layout.Section>
                </Layout>
            </Page>
        );
}

export default Helpdesk
